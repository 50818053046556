import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('infoConstructor', ['additionalInfoArticlesTooltips']),
  },

  methods: {
    getTooltipText(identifier) {
      if (identifier) {
        const tooltip = this.additionalInfoArticlesTooltips.find((tooltip) => tooltip.identifier === identifier)
        return tooltip?.articles[this.$currentLocale]
      } else return ''
    },
  },
}
